import * as Actions from './actionTypes';
import { FIRMS_ACTIVE, FIRMS_IN_ACTIVE } from './actionTypes';
import axios from 'axios';
import { API_PATH, FirmsTab } from '../../constants';

export const GetFirmsList = (
    token: string,
    data: any,
    setReduxListing = true
) => {
    let type = Actions.FIRMS_ALL;
    switch (data?.tab) {
        case FirmsTab.TYPE_ACTIVE:
            type = FIRMS_ACTIVE;
            break;
        case FirmsTab.TYPE_IN_ACTIVE:
            type = FIRMS_IN_ACTIVE;
            break;
    }
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/firms`,
                headers: {
                    Authorization: token
                },
                data
            };

            const payload = (await axios(config as any))?.data?.data;

            if (setReduxListing) {
                dispatch({
                    type,
                    payload
                });
            } else {
                if (type === FIRMS_ACTIVE) {
                    dispatch({
                        type: Actions.FIRMS_ACTIVE_COUNT,
                        payload
                    });
                } else if (type === FIRMS_IN_ACTIVE) {
                    dispatch({
                        type: Actions.FIRMS_INACTIVE_COUNT,
                        payload
                    });
                } else {
                    dispatch({
                        type: Actions.FIRMS_ALL_COUNT,
                        payload
                    });
                }
            }

            return { type: 'success', response: payload };
        } catch (e) {
            throw e;
        }
    };
};

export const AddFirm = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/add-firm`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateFirm = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-firm`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const ImportFirms = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload-firms`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteFirm = (token: string, firm_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { firm_id },
                url: `${API_PATH}/admin/delete-firm`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (e) {
            throw e;
        }
    };
};

export const AttachContactWithTag = (data = null, token) => {
    return (dispatch) => {
        return axios
            .post(
                API_PATH + '/admin/firms/tags/create-with-firm-assigment',
                data,
                {
                    headers: { Authorization: token }
                }
            )
            .then((response) => {
                return { type: 'success', response: response?.data?.data };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};
export const AttachDetachContactTags = (data, token) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/admin/firms/tags/attach-tags', data, {
                headers: { Authorization: token }
            })
            .then((response) => {
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};
export const DetachContactTags = (data, token) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/admin/firms/tags/detach-tags', data, {
                headers: { Authorization: token }
            })
            .then((response) => {
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const UpdateAddressBookTag = (data, token) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/admin/firms/tags/update', data, {
                headers: { Authorization: token }
            })
            .then((response) => {
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const DeleteAddressBookTag = (data, token) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/firms/tags/delete`,
                headers: { Authorization: token }
            };

            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (e) {
            throw e;
        }
    };
};
export const GetAddressBookAllTags = (data = null, flag = false, token) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/admin/firms/tags/', data, {
                headers: { Authorization: token }
            })
            .then((response) => {
                dispatch({
                    type: flag
                        ? Actions.UPDATE_ADDRESS_BOOK_TAGS
                        : Actions.SET_ADDRESS_BOOK_TAGS,
                    payload: response?.data?.data
                });
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const CreateAddressBookTag = (data, token) => {
    return (dispatch) => {
        return axios
            .post(API_PATH + '/admin/firms/tags/create', data, {
                headers: { Authorization: token }
            })
            .then((response) => {
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetDownloadFirms = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/firms/export`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;

            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
