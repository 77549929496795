export const API_PATH = process.env.REACT_APP_API_PATH;
export const ROWS_PER_PAGE = 20;

export const UsersTab = {
    TYPE_UN_APPROVED: 'AWAITING_APPROVAL',
    TYPE_ACTIVE: 'ACTIVE',
    TYPE_SUSPENDED: 'SUSPENDED',
    TYPE_DECLINED: 'DECLINED',
    TYPE_DELETED: 'DELETED_USERS'
};
export const DealsTab = {
    TYPE_UN_APPROVED: 'AWAITING_APPROVAL',
    TYPE_PINEGRAY: 'PINEGRAY',
    TYPE_OTHERS: 'OTHER',
    TYPE_DECLINED: 'DECLINED'
};
export const FirmsTab = {
    TYPE_ACTIVE: 'ACTIVE',
    TYPE_IN_ACTIVE: 'IN_ACTIVE'
};

export const Routes = {
    home: '/',
    users: {
        users: '/users',
        awaitingApproval: '/users/awaiting-approval',
        active: '/users/active',
        suspended: '/users/suspended',
        declined: '/users/declined',
        deleted: '/users/deleted',
        editStrategy: '/users/edit-strategy/:user_id',
        editUser: '/users/edit-user/:user_id',
        viewUser: '/users/view/:user_id'
    },
    deals: {
        deals: '/deals',
        addTeam: '/deals/add-team/:equity_id',
        awaitingApproval: '/deals/awaiting-approval',
        pinegrayListings: '/deals/pinegray-listings',
        otherListings: '/deals/other-listings',
        declinedListings: '/deals/declined',
        allListings: '/deals/all',
        dealDetails: '/deals/deals-details/:equity_id',
        capTable: '/deals/cap-table/:equity_id',
        editDeal: '/deals/edit-deal/:equity_id'
    },
    fund_deals: {
        deals: '/funds',
        addTeam: '/funds/add-team/:equity_id',
        awaitingApproval: '/funds/awaiting-approval',
        pinegrayListings: '/funds/pinegray-listings',
        otherListings: '/funds/other-listings',
        declinedListings: '/funds/declined',
        allListings: '/funds/all',
        dealDetails: '/funds/funds-details/:equity_id',
        capTable: '/funds/cap-table/:equity_id',
        editDeal: '/funds/edit-deal/:equity_id'
    },
    firms: {
        firms: '/firms',
        active: '/firms/active',
        inactive: '/firms/inactive',
        editFirm: '/edit/firms/:firm_id'
    },
    offers: '/offers',
    stories: '/blogs',
    ProductsServices: '/products-services',
    editProductsServices: '/edit/products-services/:id',
    detailCategoryProducts: `/products-services/[category]/[id]`,
    editStories: '/edit/blogs/:id',
    storiesDetails: '/blogs/:id',
    referral: {
        email: '/email-referrals',
        link: '/link-referrals'
    },
    events: {
        events: '/events',
        eventsDetails: '/events/:id',
        editEvents: '/edit/events/:id'
    },
    metadata: {
        metadata: '/metadata',
        eventsDetails: '/events/:id',
        editEvents: '/edit/events/:id'
    },
    category: '/category',
    feedback: '/feedback',
    subscriptions: '/subscriptions',
    kycProcess: '/kyc',
    clientConfig: {
        index: '/client-config',
        clientConfigDetails: '/client-config/:client_id'
    },
    productsServices: {
        index: '/products-services'
    },
    changePassword: '/change-password'
};

export const INVESTMENT_ASSETS_LIST = [
    { title: 'Technology', value: 1, id: 'technology' },
    { title: 'Impact', value: 2, id: 'impact' }
];

export const BUSINESS_MODEL_LIST = [
    { id: 'b2b', value: true, title: 'B2B' },
    { id: 'b2c', value: true, title: 'B2C' },
    { id: 'b2b2c', value: true, title: 'B2B2C' }
];

const commonDealsFundingStageList = [
    { value: 1, title: 'Pre-Seed' },
    { value: 2, title: 'Seed' },
    { value: 3, title: 'Series-A' },
    { value: 4, title: 'Series-B' },
    { value: 5, title: 'Series-C' },
    { value: 6, title: 'Series-D' },
    { value: 7, title: 'Series-E' },
    { value: 8, title: 'Series-F' },
    { value: 9, title: 'Series-G+' },
    { value: 12, title: 'Pre IPO' }
];

export const STAGE_LIST = [{ value: 11, title: 'Bootstrapped' }].concat(
    commonDealsFundingStageList
);

export const CURRENTLY_RAISING_LIST = [
    { value: 10, title: 'Convertible Note' }
].concat(commonDealsFundingStageList);

export const REVENUE_RANGE_LIST = [
    { value: 'PRE_REVENUE', title: 'Pre-Revenue' },
    { value: '0_100K', title: '<$100K' },
    { value: '100K_1M', title: '$100K to < $1M' },
    { value: '1M_5M', title: '$1M to <$5M' },
    { value: '5M_10M', title: '$5M to < $10M' },
    { value: '10M_50M', title: '$10M to <$50M' },
    { value: '50M', title: '$50M+' }
];

export const FIRM_TYPE_LIST = [
    { title: 'Venture Capital', value: 1 },
    { title: 'Corporate VC', value: 2 },
    { title: 'Impact Investment', value: 3 },
    { title: 'Asset Manager', value: 4 },
    { title: 'Single Family Office', value: 5 },
    { title: 'Multi Family Office', value: 6 },
    { title: 'Foundation', value: 7 },
    { title: 'Endowment', value: 8 },
    { title: 'High-Net Worth Individual (HNI)', value: 9 },
    { title: 'Other', value: 10 }
];

export const SOCIAL_MEDIA_LIST = [
    { title: 'LinkedIn', value: 1 },
    { title: 'Twitter', value: 2 },
    { title: 'Crunchbase', value: 3 },
    { title: 'Website', value: 4 },
    { title: 'Medium', value: 5 },
    { title: 'Substack', value: 6 },
    { title: 'Other', value: 7 }
];

export const CAP_TABLE_ACCESS_LIST = [
    { title: 'No Access', value: 'NO_ACCESS' },
    { title: 'Can View', value: 'CAN_VIEW' },
    { title: 'Can Edit', value: 'CAN_EDIT' }
];

export const EDIT_DEAL_ACCESS_LIST = [
    { title: 'Allowed', value: 'Allowed' },
    { title: 'Deactivate', value: 'Deactivate' }
];
export const LIVE_URL = process.env.REACT_LIVE_URL;
export const WEBSITE_PLACEHOLDER = `e.g. ${LIVE_URL}`;
export const ALLOW_EASY_VIEW_MESSAGE =
    'Invitee can view the information without logging in';
export const EXCEL_MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
];
