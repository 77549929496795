import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { RootState } from '../../../redux/store';
import {
    ADD_NEW_USER_SIDE_MODAL,
    OPEN_ALERT_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import DragAndDrop from '../../DragAndDrop';
import useStateRef from 'react-usestateref';
import imageCompression from 'browser-image-compression';
import { getUniqueId } from '../../../utils/common';
import {
    AddNewUserSideImages,
    UpdateNewUserSideImages,
    UploadUserSideImages
} from '../../../redux/Users/actions';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
let participantsList = [
    { title: 'Y', value: true },
    { title: 'N', value: false }
];
let placementList = [
    { title: 'Inside', value: 'INSIDE' },
    { title: 'Outside', value: 'OUTSIDE' },
    { title: 'Both', value: 'BOTH' }
];
const AddNewUserSideModal = ({
    OpenModal,
    UploadUserSideImages,
    AddNewUserSideImages,
    UpdateNewUserSideImages,
    CommonDispatcher
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const modal: any =
        useSelector((store: RootState) => store?.modal?.addNewUserSideModal) ||
        {};

    const handleClose = (flag = false) => {
        OpenModal(ADD_NEW_USER_SIDE_MODAL, {
            isOpen: false,
            isCallGetApi: flag
        });
    };
    const isEdit = modal?.data && Object.keys(modal?.data)?.length;
    const [is_enabled, setMaintenanceMode] = useState(
        isEdit ? modal?.data?.is_enabled : participantsList?.[0]?.value
    );
    const [placement, setPlacement] = useState(
        isEdit ? modal?.data?.placement : placementList?.[0]?.value
    );
    const [button_description, setButtonDiscription] = useState(
        isEdit ? modal?.data?.button_description : ''
    );
    const [popup_url, setPopupUrl] = useState(
        isEdit ? modal?.data?.popup_url : ''
    );
    const [state, setState, refState] = useStateRef<any>({});
    const [, setProgress, progressRef] = useStateRef(0);
    const [isActive, setIsActive] = useState(false);

    const handleParticipantsChange = (e, value) => {
        if (value) {
            CommonDispatcher(OPEN_ALERT_MODAL, {
                isOpen: true,
                title: 'Update',
                description:
                    'Currently active popups will be disabled Go ahead and activate',
                data: { disableBackdropClick: true },
                submitTitle: 'Yes',
                cancelTitle: 'No',
                callBack: () => {
                    CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                    setMaintenanceMode(value);
                }
            });
        } else {
            setMaintenanceMode(value);
        }
    };
    const handlePlacementChange = (e, value) => {
        setPlacement(value);
    };
    useEffect(() => {
        if (isEdit && modal?.data?.image?.length) {
            setState({
                images: [
                    {
                        id: modal?.data?.id,
                        src: modal?.data?.image
                    }
                ]
            });
        }
    }, []);
    const onDrop = useCallback(async (files) => {
        if (files && files.length) {
            const currentIndex =
                refState?.current?.images && refState?.current?.images?.length;
            const file_mame = files.map((file) =>
                file.name ? file.name : file.path ? file.path : 'Unknown'
            );
            files = files.map(
                (file, i) =>
                    new File([file], `${i + currentIndex}`, {
                        type: file.type
                    })
            );
            const updateProgress = (p) => {
                if (p == 100) {
                    setProgress(progressRef.current + 1);
                    if (files.length == progressRef.current) {
                        setIsActive(false);
                        setProgress(0);
                    }
                }
            };
            setIsActive(true);
            let prom = [];
            for (let index in files) {
                prom.push(
                    imageCompression(files[index], {
                        maxSizeMB: 1,
                        onProgress: updateProgress
                    })
                );
            }
            Promise.all(prom).then((files) => {
                files.map((file, i) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setState({
                            ...refState.current,
                            images: [
                                ...(refState?.current?.images || []),
                                {
                                    file,
                                    id: getUniqueId(),
                                    //@ts-ignore
                                    src: e.target.result,
                                    apiName: file.name,
                                    name: file_mame[i],
                                    size: file.size
                                        ? `${(
                                              (file.size / 1024) *
                                              0.0009765625
                                          ).toFixed(2)} MB`
                                        : 0
                                }
                            ]
                        });
                    };
                    reader.readAsDataURL(file);
                    return file;
                });
            });
        }
        // eslint-disable-next-line
    }, []);

    const handleDeleteImage = (id) => {
        if (id) {
            setState({
                ...refState?.current,
                images: refState?.current?.images?.filter((d) => d.id !== id)
            });
        }
    };

    const getImagesData = (images, id) => {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('type', 'POPUP');
        images.forEach((image: any) => {
            formData.append('files', image?.file as any, image?.apiName);
        });
        return formData;
    };

    const handleSave = () => {
        const action = isEdit ? UpdateNewUserSideImages : AddNewUserSideImages;
        const payload: any = {
            is_enabled,
            placement,
            popup_url,
            button_description
        };

        if (isEdit) {
            payload.id = modal?.data?.id;
        }

        action(token, payload)
            .then((r) => {
                const images = refState.current?.images?.filter((d) => d.file);

                if (images?.length) {
                    UploadUserSideImages(
                        token,
                        getImagesData(
                            images,
                            r?.response?.id || modal?.data?.id
                        )
                    )
                        .then((d) => {
                            if (d?.type === 'success') {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: isEdit
                                        ? 'Post updated successfully.'
                                        : 'Post added successfully.'
                                });
                                handleClose(true);
                            } else {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Failed to update the post.'
                                });
                                handleClose();
                            }
                        })
                        .catch(() => {
                            handleClose();
                        });
                } else {
                    if (r?.type === 'success') {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: isEdit
                                ? 'Post updated successfully.'
                                : 'Post added successfully.'
                        });
                        handleClose(true);
                    } else {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to update the post.'
                        });
                        handleClose();
                    }
                }
            })
            .catch((err) => {
                handleClose();
            });
    };

    const handleDescription = (e) => {
        setButtonDiscription(e.target.value);
    };

    const handlePopupUrl = (e) => {
        setPopupUrl(e.target.value);
    };

    return (
        <Dialog
            className="w-100"
            maxWidth={'xs'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <DragAndDrop
                    onDrop={onDrop}
                    images={state?.images}
                    isLoading={isActive}
                    deleteImage={handleDeleteImage}
                    accept={'image/png, image/jpg, image/jpeg'}
                    maxFiles={1}
                    label="Upload Photo"
                />
                <div className="mt-4">
                    <RenderToggleSwitch
                        id="is_enabled"
                        value={is_enabled}
                        label="Is enabled"
                        buttonClassName="w-100"
                        onChange={handleParticipantsChange}
                        dataArray={participantsList}
                    />
                </div>
                <div className="mt-4">
                    <RenderTextField
                        id="button_description"
                        value={button_description}
                        label="Button text"
                        type="website"
                        onChange={handleDescription}
                    />
                </div>
                <div className="mt-4">
                    <RenderTextField
                        id="popup_url"
                        value={popup_url}
                        label="Organiser website"
                        type="website"
                        onChange={handlePopupUrl}
                    />
                </div>
                <div className="mt-4">
                    <RenderToggleSwitch
                        id="placement"
                        value={placement}
                        label="Placement"
                        buttonClassName="w-100"
                        onChange={handlePlacementChange}
                        dataArray={placementList}
                    />
                </div>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{
                        marginTop: '20px'
                    }}
                >
                    {isEdit ? 'Update' : 'Save Changes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    UploadUserSideImages,
    AddNewUserSideImages,
    UpdateNewUserSideImages,
    CommonDispatcher
})(AddNewUserSideModal);
