import { Button, Card, CardContent, Container, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import {
    GetSingleClient,
    UpdateClient
} from '../../../redux/ClientConfig/actions';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { ACCOUNT_TYPE } from '../../../utils/constants';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { TOGGLE_STORY_POST } from '../../Users/Common';
import DealNotFound from './../../../resources/deal-not-found.svg';
import style from './Style.module.scss';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import { ADD_SUPPORT_USER_MODAL } from '../../../redux/ModalReducer/actionTypes';

const adminConfigs = [
    {
        admin_user_tab: 'Admin Users'
    },
    {
        admin_user_ecosystem_tab: 'Ecosystem users'
    },
    {
        admin_startup_deals_tab: 'Startup deals'
    },
    {
        admin_fund_deals_tab: 'FundFundraise deals'
    },
    {
        admin_startup_firms_tab: 'Startup firms'
    },
    {
        admin_fund_firms_tab: 'Fund firms'
    },
    {
        admin_referal_tab: 'Referral'
    },
    {
        admin_feedback_tab: 'Feedback'
    },
    {
        admin_stories_tab: 'Stories'
    },
    {
        admin_events_tab: 'Events'
    },
    {
        user_prespective_tab: 'News'
    },
    {
        admin_client_configuration_tab: 'Client config'
    },
    { admin_global_configuration_tab: 'Global config' },
    { admin_metadata_tab: 'MetaData Tab' },
    { admin_bizservice_tab: 'Show Products/Service' }
];

const globalRolesConfig = [
    { key: 'user_discussion_tab', title: 'Discussion Tab' },
    {
        key: 'allow_submit_discussion_comment',
        title: 'Discussion Comment'
    },
    { key: 'allow_submit_discussion', title: 'Allow Submit Discussion' },
    { key: 'user_fundraise_tab_company', title: 'Fundraise Company' },
    {
        key: 'user_fundraise_tab_fund',
        title: 'Fundraise Fund'
    },
    {
        key: 'user_invest_tab_company',
        title: 'Invest Company'
    },
    {
        key: 'user_invest_tab_fund',
        title: 'Invest Fund'
    },
    { key: 'user_portfolio_tab_company', title: 'Portfolio Company' },
    { key: 'user_portfolio_tab_fund', title: 'Portfolio Fund' },

    {
        key: 'user_reporting_tab_sent',
        title: 'Reporting (Sent)'
    },
    {
        key: 'user_reporting_tab_received',
        title: 'Reporting (Received)'
    },
    { key: 'user_list_investment_firms_tab', title: 'Show VCs' },
    { key: 'user_list_startup_firms_tab', title: 'Show Startup Firms' },
    {
        key: 'user_list_private_investor_firms_tab',
        title: 'Show Private Investors'
    },
    {
        key: 'user_list_lp_investment_firms_tab',
        title: 'Show LP Investors'
    },
    {
        key: 'user_list_familyoffice_investment_firms_tab',
        title: 'Show Family Office'
    },
    {
        key: 'user_list_ecosystem_firms_tab',
        title: 'Show Ecosystem Investors'
    },
    {
        key: 'user_bizservice_tab',
        title: 'Show Products/Service'
    },
    {
        key: 'allow_bizservice_post',
        title: 'Allow Products/Service'
    },
    {
        key: 'user_contacts_tab',
        title: 'Contacts'
    },
    {
        key: 'allow_refer_contacts',
        title: 'Refer Contacts'
    },
    { key: 'user_prespective_tab', title: 'News' },
    { key: 'allow_submit_story_comment', title: 'News Comments' },

    { key: 'user_event_tab', title: 'Event' },
    { key: 'user_feedback_tab', title: 'Feedback' },
    { key: 'allow_singup', title: 'Signup' },
    { key: 'allow_story_post', title: 'Story Post' },
    {
        key: 'user_marketing_tab',
        title: 'Marketing Emails'
    },
    { key: 'user_inactive_menu_tab', title: 'Menu Control' },
    { key: 'user_hide_inactive_feature', title: 'Show Inactive Menu' },
    {
        key: 'user_marketing_view_tab',
        title: 'Marketing View Emails'
    },
    {
        key: 'allow_cap_table_access',
        title: 'Cap Table Access'
    },
    { key: 'allow_team_up_with_newnex', title: 'Team up with Newnex' },
    { key: 'allow_granular_user_search', title: 'Advanced Invite' },
    { key: 'allow_submit_deal', title: 'Profile Submit Deal' },
    {
        key: 'user_profile_submit_deal_tab',
        title: 'Show Submit Deal button (profile)'
    },
    { key: 'allow_send_message', title: 'Profile Send Message' },
    { key: 'allow_events_post', title: 'Events Post' },
    { key: 'allow_invite_users', title: 'Invite investors' },
    { key: 'user_chat_tab', title: 'User Chat' },
    { key: 'allow_chat_users_list', title: 'Chat User List' },
    { key: 'user_about_tab', title: 'User About (Strategy)' },
    {
        key: 'allow_singup_company_website',
        title: 'Company Name SignUp Show'
    },
    {
        key: 'user_max_contacts_limit',
        title: 'Max Contacts Allowed'
    },
    {
        key: 'user_addressbook_list_contacts_limit_count',
        title: 'Contact Lists Limit'
    },
    {
        key: 'user_max_upload_limit',
        title: 'Upload Limit'
    },
    {
        key: 'user_max_storage_limit',
        title: 'Max Storage Limit (in MB)'
    }
];
let rolesConfigsData = [
    {
        title: 'Institutional investor',
        type: ACCOUNT_TYPE.INVESTOR,
        globalRolesConfig
    },
    {
        title: 'Company (Startup)',
        type: ACCOUNT_TYPE.STARTUP,
        globalRolesConfig
    },
    {
        title: 'Private investor',
        type: ACCOUNT_TYPE.PRIVATE_INVESTOR,
        globalRolesConfig
    },
    { title: 'Ecosystem', type: ACCOUNT_TYPE.ECOSYSTEM, globalRolesConfig }
];
const ClientConfigDetails = ({
    match,
    GetSingleClient,
    CommonDispatcher,
    OpenModal,
    UpdateClient
}: any) => {
    const [d, setDeal]: any = useState({});
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);
    const [dealNotFound, setDealNotFound] = useState(false);
    const [contactListLimit, setContactListLimit] = useState({});
    const [isClUpdate, setIscLUpdate] = useState({});
    const {
        params: { client_id }
    } = match;
    const [statuses, setStatuses]: any = useState({});
    const [limitData, setLimitData]: any = useState([]);

    const getClientData = (flag = false) => {
        GetSingleClient(token, client_id).then((r) => {
            if (r?.response?.data?.error) {
                setDealNotFound(true);
                setIsLoading(false);
            } else {
                const deal = r?.response?.client;
                const selectedClientConfigData = deal?.globalRolesConfig;

                if (
                    selectedClientConfigData &&
                    selectedClientConfigData.length
                ) {
                    setLimitData(selectedClientConfigData);

                    let dealGlobalRolesConfig = deal?.globalRolesConfig;

                    if (dealGlobalRolesConfig && dealGlobalRolesConfig.length) {
                        dealGlobalRolesConfig = dealGlobalRolesConfig.map(
                            (d) => {
                                let obj = { ...d };

                                const keys = Object.keys(d);
                                globalRolesConfig.forEach((z) => {
                                    keys.forEach((a) => {
                                        if (a === z?.key) {
                                            obj[a] = !!d[a] || false;
                                        }
                                    });
                                });
                                return obj;
                            }
                        );
                        deal.globalRolesConfig = dealGlobalRolesConfig;
                    }
                }
                setDeal(deal);
                setStatuses(deal);
                setIsLoading(false);
                if (flag) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Status updated.',
                        hideAlert: true
                    });
                }
            }
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getClientData();
    }, []);

    const handleToggleRolesType = (
        key,
        value,
        type = null,
        id,
        updateKey = null
    ) => {
        let obj = {};
        if (
            key === 'user_addressbook_list_contacts_limit_count' ||
            key === 'user_max_upload_limit' ||
            key === 'user_max_contacts_limit' ||
            key === 'user_max_storage_limit'
        ) {
            obj = { globalRolesConfig: [{ [key]: Number(value), type }] };
        } else {
            obj = { globalRolesConfig: [{ [key]: !!value, type }] };
        }

        if (statuses.globalRolesConfig && statuses.globalRolesConfig.length) {
            statuses.globalRolesConfig = statuses.globalRolesConfig.map((d) => {
                if (d?.id === id) {
                    d[key] = value;
                }
                return d;
            });
        }
        setStatuses(statuses);
        UpdateClient(token, { client_id, ...obj }).then((r) => {
            if (r?.response?.data?.error) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to update status.',
                    hideAlert: true,
                    error: true
                });
            } else {
                getClientData(true);
                updateKey &&
                    setIscLUpdate({ ...isClUpdate, [updateKey]: false });
            }
        });
    };

    const handleToggleType = (key, value) => {
        let obj = {};
        if (
            key === 'user_addressbook_list_contacts_limit_count' ||
            key === 'user_max_upload_limit' ||
            key === 'user_max_contacts_limit' ||
            key === 'user_max_storage_limit'
        ) {
            obj = { [key]: Number(value) };
        } else {
            obj = { [key]: !!value };
        }

        setStatuses({ ...statuses, ...obj });
        UpdateClient(token, { client_id, ...obj }).then((r) => {
            if (r?.response?.data?.error) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to update status.',
                    hideAlert: true,
                    error: true
                });
            } else {
                getClientData(true);
            }
        });
    };
    const handleContactListLimit = (e, key) => {
        setIscLUpdate({ ...isClUpdate, [key]: true });
        setContactListLimit({ ...contactListLimit, [key]: e.target.value });
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <div className={`h-100 mt-5 ${style.pageContainer}`}>
            <Grid container id="listingContainer" className="h-100">
                <Grid item xs={12} className="m-auto">
                    {dealNotFound ? (
                        <div className="d-flex align-items-center flex-direction-column">
                            <img
                                src={DealNotFound}
                                alt="Deal not found"
                                height={250}
                                width={250}
                            />
                            <h1>Client not found!</h1>
                        </div>
                    ) : (
                        <Card className="mt-5 p-5">
                            <div className={style.cardContainer}>
                                <CardContent className="p-0">
                                    <Container maxWidth="xl" className="p-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2>
                                                Client:&nbsp;
                                                <b className="colorBlue">
                                                    {d?.name}
                                                </b>
                                            </h2>
                                            <Button
                                                onClick={() =>
                                                    OpenModal(
                                                        ADD_SUPPORT_USER_MODAL,
                                                        {
                                                            title: 'Add Support',
                                                            isOpen: true,
                                                            data: d
                                                        }
                                                    )
                                                }
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                            >
                                                Add Support
                                            </Button>
                                        </div>

                                        <Grid container>
                                            <Grid item sm={12} md={2}>
                                                <h2 className="text-center">
                                                    Admin
                                                </h2>

                                                {adminConfigs.map((d) => {
                                                    const key =
                                                        Object.entries(d)[0][0];
                                                    const value =
                                                        Object.entries(d)[0][1];
                                                    return (
                                                        <div
                                                            className="mb-3 px-5"
                                                            key={key}
                                                        >
                                                            <RenderToggleSwitch
                                                                label={value}
                                                                inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                                                buttonGroupClassName="w-unset ml-3"
                                                                value={Boolean(
                                                                    statuses[
                                                                        key
                                                                    ]
                                                                )}
                                                                onChange={(
                                                                    e,
                                                                    value
                                                                ) =>
                                                                    handleToggleType(
                                                                        key,
                                                                        value
                                                                    )
                                                                }
                                                                dataArray={
                                                                    TOGGLE_STORY_POST
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </Grid>
                                            <Grid item sm={12} md={10}>
                                                <Grid container>
                                                    {rolesConfigsData.map(
                                                        (r, i) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={6}
                                                                    md={3}
                                                                    key={i}
                                                                >
                                                                    <h2 className="text-center">
                                                                        {
                                                                            r?.title
                                                                        }
                                                                    </h2>

                                                                    {r?.globalRolesConfig &&
                                                                        r
                                                                            ?.globalRolesConfig
                                                                            .length &&
                                                                        r?.globalRolesConfig.map(
                                                                            (
                                                                                a
                                                                            ) => {
                                                                                const key =
                                                                                    a?.key;
                                                                                const value =
                                                                                    a?.title;
                                                                                const id =
                                                                                    d
                                                                                        ?.globalRolesConfig?.[
                                                                                        i
                                                                                    ]
                                                                                        ?.id;
                                                                                return (
                                                                                    <div
                                                                                        className={`mb-3 px-5 ${
                                                                                            key ===
                                                                                            'user_marketing_tab'
                                                                                                ? 'border-bottom-5px pb-3'
                                                                                                : ''
                                                                                        }`}
                                                                                        key={
                                                                                            key
                                                                                        }
                                                                                    >
                                                                                        {key ===
                                                                                            'user_addressbook_list_contacts_limit_count' ||
                                                                                        key ===
                                                                                            'user_max_upload_limit' ||
                                                                                        key ===
                                                                                            'user_max_contacts_limit' ||
                                                                                        key ===
                                                                                            'user_max_storage_limit' ? (
                                                                                            <>
                                                                                                <RenderTextField
                                                                                                    label={
                                                                                                        value
                                                                                                    }
                                                                                                    defaultValue={
                                                                                                        limitData[
                                                                                                            i
                                                                                                        ]?.[
                                                                                                            key
                                                                                                        ]
                                                                                                    }
                                                                                                    size="small"
                                                                                                    type="number"
                                                                                                    // autoFocus={
                                                                                                    //     true
                                                                                                    // }
                                                                                                    onChange={(
                                                                                                        e: any
                                                                                                    ) => {
                                                                                                        handleContactListLimit(
                                                                                                            e,
                                                                                                            key +
                                                                                                                i
                                                                                                        );
                                                                                                    }}
                                                                                                    placeholder="Enter limit"
                                                                                                />
                                                                                                <Button
                                                                                                    onClick={() =>
                                                                                                        handleToggleRolesType(
                                                                                                            key,
                                                                                                            contactListLimit[
                                                                                                                key +
                                                                                                                    i
                                                                                                            ],
                                                                                                            r?.type,
                                                                                                            id,
                                                                                                            key +
                                                                                                                i
                                                                                                        )
                                                                                                    }
                                                                                                    color="primary"
                                                                                                    variant="contained"
                                                                                                    size="small"
                                                                                                    className="mt-2 w-100"
                                                                                                    disabled={
                                                                                                        !isClUpdate[
                                                                                                            key +
                                                                                                                i
                                                                                                        ]
                                                                                                    }
                                                                                                >
                                                                                                    Update
                                                                                                </Button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <RenderToggleSwitch
                                                                                                toggleButtonGroupId={
                                                                                                    key
                                                                                                }
                                                                                                label={
                                                                                                    value
                                                                                                }
                                                                                                inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                                                                                buttonGroupClassName="w-unset ml-3"
                                                                                                value={
                                                                                                    d
                                                                                                        ?.globalRolesConfig?.[
                                                                                                        i
                                                                                                    ]?.[
                                                                                                        key
                                                                                                    ]
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                    value
                                                                                                ) =>
                                                                                                    handleToggleRolesType(
                                                                                                        key,
                                                                                                        value,
                                                                                                        r?.type,
                                                                                                        id
                                                                                                    )
                                                                                                }
                                                                                                dataArray={
                                                                                                    TOGGLE_STORY_POST
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </CardContent>
                            </div>
                        </Card>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, {
    GetSingleClient,
    CommonDispatcher,
    OpenModal,
    UpdateClient
})(ClientConfigDetails);
