import { Button, Popover, useMediaQuery, useTheme } from '@material-ui/core';
import { Restore, Tune } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import {
    PHONE_PROFILE_STATUS,
    SOFT_PROFILE_STATUS,
    TOGGLE_STORY_POST,
    USERS_ADMIN_TOGGLE_OPTIONS,
    USERS_TOGGLE_OPTIONS
} from '../Common';
import style from './Styles.module.scss';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from '../../../redux/store';
import _ from 'lodash';

const rolesConfigsData = [
    {
        key: 'soft_profile_status',
        title: 'Soft Profiles',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'step_complete_strategy',
        title: 'Strategy Completed',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'email_status',
        title: 'Email Status',
        switch: PHONE_PROFILE_STATUS
    },
    {
        key: 'phone_status',
        title: 'Phone Status',
        switch: PHONE_PROFILE_STATUS
    },
    {
        key: 'allow_global_config',
        title: 'Global config',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_affiliation_visibility',
        title: 'User Affiliation Visibility',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'global_affiliation_visibility',
        title: 'Global Affiliation Visibility',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'role_type',
        title: 'Discussions',
        switch: USERS_ADMIN_TOGGLE_OPTIONS
    },
    {
        key: 'note',
        title: 'Note',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'is_support_user',
        title: 'User Support',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_startup_firm_list',
        title: 'Show in Startup Network',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_ecosystem_firm_list',
        title: 'Show in Ecosystem Network',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_vc_firm_list',
        title: 'Show in VC Network',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_lp_firm_list',
        title: 'Show in LP Network',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_familyoffice_firm_list',
        title: 'Show in Family Office Network',
        switch: USERS_TOGGLE_OPTIONS
    },
    {
        key: 'user_show_in_private_investor_list',
        title: 'Show in Private Investors Network',
        switch: USERS_TOGGLE_OPTIONS
    }
];

const FilterUserModal = ({ title, documents }: any) => {
    const [decodedFilters, setDecodeFilter] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [statuses, setStatuses]: any = React.useState({});
    const [filters, setFilters] = React.useState({});
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [eco_system_type, setEcoType] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    const ecoSystemTypeList = useSelector(
        (store: RootState) => store?.commonAppData.ecoSystemTypeList || []
    );

    useEffect(() => {
        if (filterParam) {
            setDecodeFilter(JSON.parse(decodeURIComponent(filterParam)));
        } else {
            setStatuses({});
        }
    }, [filterParam, anchorEl]);
    const history = useHistory();
    const open = Boolean(anchorEl);
    const popUpId = open ? `popover` : undefined;
    const { register, control } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    useEffect(() => {
        let record = [];
        ecoSystemTypeList.map((x) => {
            if (decodedFilters?.ecosystem_type_ids?.includes(x.value)) {
                return record.push(x);
            }
        });
        setEcoType(record);
    }, []);
    const handleOpenPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const clearAllFilter = (event) => {
        let params = new URLSearchParams(location.search);
        params.delete('filter');
        const newQueryString = params.toString();

        history.replace({
            pathname: location.pathname,
            search: newQueryString
        });
        setStatuses({});
        setFilters({});
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggleRolesType = (e, key) => {
        let value;
        switch (key) {
            case 'soft_profile_status':
                value = e === true ? 'SHOW' : 'HIDE';
                break;
            case 'phone_status':
                value = e === true ? 'VERIFIED' : 'UNVERIFIED';
                break;
            case 'email_status':
                value = e === true ? 'VERIFIED' : 'UNVERIFIED';
                break;
            case 'step_complete_strategy':
                value = e === true ? 'DONE' : 'NOTDONE';
                break;
            case 'allow_global_config':
                value = e;
                break;
            case 'note':
                value = e;
                break;
            case 'is_support_user':
                value = e;
                break;
            case 'user_affiliation_visibility':
                value = e;
                break;
            case 'global_affiliation_visibility':
                value = e;
                break;
            case 'role_type':
                value = e;
                break;
            case 'ecosystem_type_ids':
                value = e;
                break;
            case 'user_show_in_startup_firm_list':
                value = e;
                break;
            case 'user_show_in_ecosystem_firm_list':
                value = e;
                break;
            case 'user_show_in_vc_firm_list':
                value = e;
                break;
            case 'user_show_in_lp_firm_list':
                value = e;
                break;
            case 'user_show_in_familyoffice_firm_list':
                value = e;
                break;
            case 'user_show_in_private_investor_list':
                value = e;
                break;
            default:
                break;
        }
        setStatuses({ ...statuses, [key]: e });
        setFilters({ ...filters, [key]: value });

        const newFilters = {
            ...filters,
            [key]: value
        };

        const searchParams = new URLSearchParams(location.search);
        searchParams.set(
            'filter',
            encodeURIComponent(JSON.stringify(newFilters))
        );

        const newQueryString = searchParams.toString();

        history.replace({
            pathname: location.pathname,
            search: newQueryString
        });
    };
    const delayedQuery = useCallback(
        _.debounce((value, key) => handleToggleRolesType(value, key), 1000),
        []
    );
    const handleFirmTypeChange = (key, value) => {
        setEcoType(value);
        if (key === 'ecosystem_type_ids') {
            value = value.map((d) => d?.value);
        }
        delayedQuery(value, key);
    };

    return (
        <div className={`${style.container}`}>
            <ToggleButton
                classes={{ selected: 'bgColorWhite' }}
                style={{ borderRadius: '10px' }}
                onClick={handleOpenPopup}
                className="ml-3"
            >
                <Tune className="colorPrimary" />
            </ToggleButton>
            {open ? (
                <Popover
                    id={popUpId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {open ? (
                        <div className={`p-3 text-center`}>
                            {(rolesConfigsData || []).map((r, i) => {
                                return (
                                    <>
                                        <div className="mb-3 px-3" key={r.key}>
                                            <RenderToggleSwitch
                                                toggleButtonGroupId={r.key}
                                                label={r?.title}
                                                inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                                buttonGroupClassName="w-unset ml-3"
                                                value={statuses[r.key]}
                                                onChange={(e, value) =>
                                                    handleToggleRolesType(
                                                        value,
                                                        r?.key
                                                    )
                                                }
                                                dataArray={r?.switch}
                                            />
                                        </div>
                                    </>
                                );
                            })}
                            <div className="my-5">
                                <Controller
                                    name={'ecosystem_type_ids'}
                                    render={({ onChange }) => (
                                        <div className="w-100">
                                            <RenderAutocompleteTextField
                                                value={eco_system_type}
                                                label="EcoSystem Type"
                                                inputAutoComplete="off"
                                                disableCloseOnSelect={
                                                    !smallScreen
                                                }
                                                size="small"
                                                onChange={(e, data) => {
                                                    onChange(data);
                                                    handleFirmTypeChange(
                                                        'ecosystem_type_ids',
                                                        data
                                                    );
                                                }}
                                                options={ecoSystemTypeList}
                                                inputPlaceholder="Search investment type"
                                            />
                                        </div>
                                    )}
                                    control={control}
                                    inputRef={register}
                                />
                            </div>
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                className="mt-2"
                                onClick={clearAllFilter}
                            >
                                <Restore className={style.clearIcon} />
                                Reset
                            </Button>
                        </div>
                    ) : null}
                </Popover>
            ) : null}
        </div>
    );
};

export default connect(null, {})(FilterUserModal);
