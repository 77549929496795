import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { GetFirmsList, UpdateFirm } from '../../../redux/Firms/actions';
import { SHOW_USER_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { SOFT_PROFILE_STATUS } from '../Common/WebsiteURLWrapper';
import { getQuery } from '../../../utils/common';
import { useHistory, useLocation } from 'react-router-dom';
import { ROWS_PER_PAGE } from '../../../constants';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const showUserData = [
    {
        key: 'is_startup_enabled',
        title: 'Show in Startups',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_ecosystem_enabled',
        title: 'Show in Ecosystem',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_enabled',
        title: 'Show in VCs',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_lp_enabled',
        title: 'Show in LPs',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_family_enabled',
        title: 'Show in Family',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_public_enabled',
        title: 'Send Public Logo',
        switch: SOFT_PROFILE_STATUS
    },
    {
        key: 'is_public_website_enabled',
        title: 'Send Public URL',
        switch: SOFT_PROFILE_STATUS
    }
];
const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ShowUserModal = ({ title, UpdateFirm, OpenModal, GetFirmsList }) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.showUserModal) || {};
    const [status, setStatus] = useState({});

    const firm_id = modal?.data?.selectedUser?.firm_id;

    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const history = useHistory();
    const query = getQuery(history);
    const location = useLocation();

    const tags = query.get('tags_ids');
    const type = query.get('type');
    const keyword = query.get('keyword');

    useEffect(() => {
        setStatus(modal?.data?.selectedUser);
        return () => {
            GetFirmsList(token, {
                keyword,
                type,
                filter: { tags_ids: tags ? tags?.split(',') : null },
                page: modal?.data?.current_page,
                size: modal?.data?.size || ROWS_PER_PAGE,
                tab: modal?.data?.tab
            });
        };
    }, []);

    const handleToggleType = async (key, value) => {
        UpdateFirm(token, { firm_id, [key]: value }).then((x) => {});
        setStatus({ ...status, [key]: value });
    };

    const handleClose = () => {
        OpenModal(SHOW_USER_MODAL, { isOpen: false });
    };

    return (
        <>
            <Dialog
                maxWidth={modal?.config?.modalSize || 'xs'}
                fullWidth={modal?.config?.fullWidth || false}
                open={modal?.isOpen || false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={() => handleClose()}
                >
                    {modal?.title || title}
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex flex-direction-xs-column">
                        <div className="d-flex flex-direction-column">
                            <div className="mb-3 px-5">
                                {(showUserData || []).map((r, i) => {
                                    return (
                                        <>
                                            <div
                                                className="mb-3 px-3"
                                                key={r.key}
                                            >
                                                <RenderToggleSwitch
                                                    toggleButtonGroupId={r.key}
                                                    label={r?.title}
                                                    inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                                    buttonGroupClassName="w-unset ml-3"
                                                    value={!!status[r?.key]}
                                                    onChange={(e, value) =>
                                                        handleToggleType(
                                                            r?.key,
                                                            value
                                                        )
                                                    }
                                                    dataArray={r?.switch}
                                                />
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="d-flex align-items-center justify-content-center">
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default connect(null, {
    OpenModal,
    CommonDispatcher,
    GetFirmsList,
    UpdateFirm
})(ShowUserModal);
