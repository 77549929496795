import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    List,
    ListItem,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { CheckCircle, Close, RadioButtonUnchecked } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    USERS_ADMIN_TOGGLE_OPTIONS,
    USERS_TOGGLE_OPTIONS,
    TOGGLE_STORY_POST
} from '.';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import {
    ADD_MARKETING_MODAL,
    FIRM_LIST_MODAL,
    OPEN_ALERT_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import {
    GetUsersList,
    UpdateUserPermission,
    UpdateUserProfile
} from '../../../redux/Users/actions';
import { RootState } from '../../../redux/store';
import { getQuery } from '../../../utils/common';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { ACCOUNT_TYPE } from '../../../utils/constants';

let userSettingsFlags = [
    { key: 'user_discussion_tab', title: 'Discussion Tab' },
    {
        key: 'allow_submit_discussion_comment',
        title: 'Discussion Comment'
    },
    { key: 'allow_submit_discussion', title: 'Allow Submit Discussion' },
    { key: 'user_list_investment_firms_tab', title: 'Show VCs' },
    { key: 'user_list_startup_firms_tab', title: 'Show Startup Firms' },
    {
        key: 'user_list_private_investor_firms_tab',
        title: 'Show Private Investors'
    },
    {
        key: 'user_list_lp_investment_firms_tab',
        title: 'Show LP Investors'
    },
    {
        key: 'user_list_familyoffice_investment_firms_tab',
        title: 'Show Family Office'
    },
    {
        key: 'user_list_ecosystem_firms_tab',
        title: 'Show Ecosystem Investors'
    },
    {
        key: 'user_bizservice_tab',
        title: 'Show Products/Service'
    },
    {
        key: 'allow_bizservice_post',
        title: 'Allow Products/Service'
    },
    {
        key: 'user_marketing_tab',
        title: 'Marketing Emails'
    },
    {
        key: 'user_marketing_view_tab',
        title: 'Marketing View Emails'
    },
    { key: 'user_event_tab', title: 'Event' },
    {
        key: 'user_prespective_tab',
        title: 'News'
    },
    { key: 'allow_submit_story_comment', title: 'News Comments' },
    { key: 'user_about_tab', title: 'User About (Strategy)' },
    {
        key: 'user_reporting_tab_sent',
        title: 'Reporting (Sent)'
    },
    {
        key: 'user_reporting_tab_received',
        title: 'Reporting (Received)'
    },
    { key: 'user_portfolio_tab_company', title: 'Portfolio Company' },
    { key: 'user_portfolio_tab_fund', title: 'Portfolio Fund' },
    {
        key: 'user_fundraise_tab_fund',
        title: 'Fund Fundraise'
    },
    {
        key: 'user_invest_tab_fund',
        title: 'Fund Invest'
    },
    {
        key: 'allow_singup_company_website',
        title: 'Company Name SignUp Show'
    }
].map((i) => ({ ...i, options: [...USERS_TOGGLE_OPTIONS] }));

let permissionFlags = [
    { key: 'user_inactive_menu_tab', title: 'Menu Control' },
    { key: 'user_hide_inactive_feature', title: 'Show Inactive Menu' },
    { key: 'user_fundraise_tab_company', title: 'Fundraise Company' },
    { key: 'user_invest_tab_company', title: 'Invest Company' },
    { key: 'allow_events_post', title: 'Post Events' },
    { key: 'allow_story_post', title: 'Post Stories' },
    { key: 'allow_granular_user_search', title: 'Advanced Invite' },
    { key: 'allow_invite_users', title: 'Invite Users' },
    { key: 'allow_cap_table_access', title: 'Captable' },
    { key: 'allow_team_up_with_newnex', title: 'Team-up with newnex' },
    { key: 'allow_send_message', title: 'Send Msg (Profile)' },
    { key: 'allow_submit_deal', title: 'Submit Deal (Profile)' },
    {
        key: 'user_profile_submit_deal_tab',
        title: 'Show Submit Deal button (profile)'
    },
    { key: 'user_contacts_tab', title: 'Contacts' },
    {
        key: 'allow_refer_contacts',
        title: 'Refer Contacts'
    },
    { key: 'user_chat_tab', title: 'User Chat' },
    { key: 'allow_chat_users_list', title: 'Chat User List' }
].map((i) => ({
    ...i,
    options: [...USERS_TOGGLE_OPTIONS],
    type: 'PERMISSION_FLAGS'
}));
const showUserData = [
    {
        key: 'user_show_in_startup_firm_list',
        title: 'Show in Startups',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'user_show_in_ecosystem_firm_list',
        title: 'Show in Ecosystem',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'user_show_in_vc_firm_list',
        title: 'Show in VCs',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'user_show_in_lp_firm_list',
        title: 'Show in LPs',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'user_show_in_familyoffice_firm_list',
        title: 'Show in Family',
        switch: TOGGLE_STORY_POST
    },
    {
        key: 'user_show_in_private_investor_list',
        title: 'Show in Private',
        switch: TOGGLE_STORY_POST
    }
];
let settingsFlags = userSettingsFlags.concat(permissionFlags);
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const FirmsModal = ({
    title,
    GetUsersList,
    CommonDispatcher,
    UpdateUserProfile,
    UpdateUserPermission,
    OpenModal
}) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.firmListModal) || {};
    const [modalData, setModalData] = useState(modal?.data?.selectedUser);
    const [isInputUpdate, setIsInputUpdate] = useState({});
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type') || '';
    const keyword = query.get('keyword') || '';
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [dataArray, setDataArray] = useState([]);
    const [limitInput, setLimitInput] = useState([]);

    const LimitInput = [
        {
            key: 'user_max_contacts_limit',
            title: 'Max Contacts Allowed',
            value: modalData?.user_max_contacts_limit
        },
        {
            key: 'user_addressbook_list_contacts_limit_count',
            title: 'Contact Lists Limit',
            value: modalData?.user_addressbook_list_contacts_limit_count
        },
        {
            key: 'user_max_upload_limit',
            title: 'Upload Limit (in MB)',
            value: modalData?.user_max_upload_limit
        },
        {
            key: 'user_max_storage_limit',
            title: 'Max Storage Limit (in MB)',
            value: modalData?.user_max_storage_limit
        }
    ].map((i) => ({
        ...i,
        disabled: false
    }));

    const DashboardRecord = [
        {
            key: 'step_setup_account',
            title: 'Setup Account',
            value: modalData?.step_setup_account
        },
        {
            key: 'step_complete_profile',
            title: 'Complete Profile Info',
            value: modalData?.step_complete_profile
        },
        {
            key: 'step_upload_photo',
            title: 'Upload Photo',
            value: modalData?.step_upload_photo
        },
        {
            key: 'step_complete_strategy',
            title: 'Update About Strategy',
            value: modalData?.step_complete_strategy
        },
        {
            key: 'step_profile_username_set',
            title: 'Setup Profile Username',
            value: modalData?.step_profile_username_set
        },
        {
            key: 'step_mobileno_verified',
            title: 'Validate Mobile Number',
            value: modalData?.step_mobileno_verified
        }
    ];

    useEffect(() => {
        return () => {
            USERS_TOGGLE_OPTIONS.map((item) => {
                item.disabled = false;
                return item;
            });
        };
    }, []);

    useEffect(() => {
        let settingsFlagsClone = [...settingsFlags];

        let firmsConfigData = settingsFlagsClone.map((i) => {
            i.options = i?.options.map((item) => {
                item.disabled = Boolean(modalData?.allow_global_config);
                return item;
            });
            return i;
        });
        setDataArray(firmsConfigData);

        let limitInputData = LimitInput.map((i) => {
            i.disabled = Boolean(modalData?.allow_global_config);
            i.value = modalData[i?.key];
            return i;
        });

        setLimitInput(limitInputData);
    }, [modalData]);

    const handleToggleType = async (key, value, flagType = null) => {
        OpenModal(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: 'Change setting',
            description: `Are you sure you want to change setting for this users?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Yes, Update',
            cancelTitle: 'Cancel',
            callBack: () => {
                let obj: any = {};

                if (
                    key === 'user_addressbook_list_contacts_limit_count' ||
                    key === 'user_max_upload_limit' ||
                    key === 'user_max_contacts_limit' ||
                    key === 'user_max_storage_limit'
                ) {
                    obj = { [key]: Number(value) };
                } else {
                    obj = { [key]: !!value };
                }
                if (key === 'role_type') {
                    obj = { [key]: value };
                }
                let user_id = modal?.data?.selectedUser?.user_id;
                if (flagType === 'PERMISSION_FLAGS') {
                    UpdateUserPermission(token, { user_id, ...obj })
                        .then(() => {
                            getUsers(user_id, key, value);
                        })
                        .catch(() => {
                            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
                        });
                } else {
                    let limitInputData = limitInput.map((i) => {
                        i.disabled = Boolean(modalData?.allow_global_config);
                        return i;
                    });
                    setLimitInput(limitInputData);
                    if (key === 'allow_global_config' && value) {
                        obj.reset_global_config = true;
                    }
                    UpdateUserProfile(token, { user_id, ...obj }).then(
                        async (r) => {
                            if (r?.response?.data?.error) {
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Failed to update status.',
                                    hideAlert: true,
                                    error: true
                                });

                                OpenModal(OPEN_ALERT_MODAL, {
                                    isOpen: false
                                });
                            } else {
                                getUsers(user_id, key, value);
                            }
                        }
                    );
                }
            }
        });
    };

    const resetHandle = () => {
        OpenModal(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: 'Change setting',
            description: `Are you sure you want to change setting for this users?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Yes, Update',
            cancelTitle: 'Cancel',
            callBack: () => {
                let user_id = modal?.data?.selectedUser?.user_id;
                UpdateUserProfile(token, {
                    user_id,
                    reset_global_config: true
                }).then(async (r) => {
                    if (r?.response?.data?.error) {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to update status.',
                            hideAlert: true,
                            error: true
                        });
                        OpenModal(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                    } else {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Status updated successfully.',
                            hideAlert: true
                        });
                        getUsers(user_id, 'reset_global_config', true);
                    }
                });
            }
        });
    };

    const getUsers = async (user_id, key, value) => {
        await GetUsersList(token, {
            keyword,
            type,
            page: modal?.data?.page + 1,
            tab: modal?.data?.tab
        })
            .then((x) => {
                setModalData(
                    x.response.users.find((d) => d.user_id == user_id)
                );
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Updated successfully.',
                    hideAlert: true
                });
                if (key === 'allow_global_config') {
                    let firmsConfigData = dataArray.map((i) => {
                        i.options = i?.options.map((item) => {
                            item.disabled = !!value;
                            return item;
                        });
                        return i;
                    });

                    setDataArray(firmsConfigData);
                    OpenModal(OPEN_ALERT_MODAL, {
                        isOpen: false
                    });
                } else {
                    OpenModal(OPEN_ALERT_MODAL, {
                        isOpen: false
                    });
                }
                setIsInputUpdate({
                    ...isInputUpdate,
                    [key]: false
                });
            })
            .catch(() => {
                OpenModal(OPEN_ALERT_MODAL, {
                    isOpen: false
                });
            });
    };

    const handleClose = () => {
        OpenModal(FIRM_LIST_MODAL, { isOpen: false });
    };
    const handleContactListLimit = (e, key = null) => {
        setIsInputUpdate({ ...isInputUpdate, [key]: true });

        let limitInputData = limitInput.map((i) => {
            if (i?.key === key) {
                i.value = e.target.value;
            }
            return i;
        });
        setLimitInput(limitInputData);
    };

    return (
        <>
            <Dialog
                maxWidth={modal?.config?.modalSize || 'sm'}
                fullWidth={modal?.config?.fullWidth || true}
                open={modal?.isOpen || false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={() => handleClose()}
                >
                    {modal?.title || title}
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex flex-direction-xs-column">
                        <div className="d-flex flex-direction-column">
                            <div className="mb-3 px-5">
                                <Button
                                    className="colorPurple"
                                    variant="text"
                                    onClick={resetHandle}
                                >
                                    Reset to Global Config
                                </Button>
                            </div>
                            <div className="mb-3 px-5">
                                <RenderToggleSwitch
                                    label={'Global Config'}
                                    inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                    buttonGroupClassName="w-unset ml-3"
                                    value={!!modalData['allow_global_config']}
                                    onChange={(e, value) =>
                                        handleToggleType(
                                            'allow_global_config',
                                            value
                                        )
                                    }
                                    dataArray={[
                                        { title: 'Default', value: true },
                                        { title: 'Custom', value: false }
                                    ]}
                                />
                            </div>
                            {(dataArray || []).map((d) => {
                                return (
                                    <div className="mb-3 px-5" key={d?.title}>
                                        <RenderToggleSwitch
                                            label={d?.title}
                                            inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                            buttonGroupClassName="w-unset ml-3"
                                            value={!!modalData[d.key] || false}
                                            onChange={(e, value) =>
                                                handleToggleType(
                                                    d?.key,
                                                    value,
                                                    d?.type
                                                )
                                            }
                                            dataArray={d?.options}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <List className="ml-10 m-xs-auto">
                            <div className="mb-3 ">
                                <RenderToggleSwitch
                                    label={'Discussion-admin activation'}
                                    inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                    buttonGroupClassName="w-unset ml-3 "
                                    value={modalData?.role_type}
                                    onChange={(e, value) =>
                                        handleToggleType('role_type', value)
                                    }
                                    dataArray={USERS_ADMIN_TOGGLE_OPTIONS}
                                />
                            </div>
                            {[ACCOUNT_TYPE.PRIVATE_INVESTOR].includes(
                                modalData?.type
                            ) ? (
                                <div className="mb-3 ">
                                    <RenderToggleSwitch
                                        label={'Show in List'}
                                        inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                        buttonGroupClassName="w-unset ml-3 "
                                        value={
                                            !!modalData[
                                                'user_show_in_private_investor_list'
                                            ] || false
                                        }
                                        onChange={(e, value) =>
                                            handleToggleType(
                                                'user_show_in_private_investor_list',
                                                value
                                            )
                                        }
                                        dataArray={TOGGLE_STORY_POST}
                                    />
                                </div>
                            ) : null}
                            {[
                                ACCOUNT_TYPE.INVESTOR,
                                ACCOUNT_TYPE.STARTUP,
                                ACCOUNT_TYPE.ECOSYSTEM
                            ].includes(modalData?.type) ? (
                                <div className="mb-3 ">
                                    {(showUserData || []).map((r, i) => {
                                        return (
                                            <>
                                                <div
                                                    className="mb-3 "
                                                    key={r.key}
                                                >
                                                    <RenderToggleSwitch
                                                        toggleButtonGroupId={
                                                            r.key
                                                        }
                                                        label={r?.title}
                                                        inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                                        buttonGroupClassName="w-unset ml-3"
                                                        value={
                                                            !!modalData[r?.key]
                                                        }
                                                        onChange={(e, value) =>
                                                            handleToggleType(
                                                                r?.key,
                                                                value
                                                            )
                                                        }
                                                        dataArray={r?.switch}
                                                    />
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className="mb-3 ">
                                <RenderToggleSwitch
                                    label={'User Support'}
                                    inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                                    buttonGroupClassName="w-unset ml-3 "
                                    value={
                                        !!modalData['is_support_user'] || false
                                    }
                                    onChange={(e, value) =>
                                        handleToggleType(
                                            'is_support_user',
                                            value
                                        )
                                    }
                                    dataArray={TOGGLE_STORY_POST}
                                />
                            </div>

                            <div className="mb-4 fs-16">Profile Statuses</div>
                            {DashboardRecord.map((item) => {
                                return (
                                    <ListItem
                                        key={item.title}
                                        dense
                                        disableGutters
                                        style={{
                                            lineHeight: '14px',
                                            marginBottom: '2px'
                                        }}
                                    >
                                        <div className="mr-2 d-flex align-items-center">
                                            {item?.value ? (
                                                <CheckCircle className="colorPurple" />
                                            ) : (
                                                <RadioButtonUnchecked className="colorPrimary" />
                                            )}
                                        </div>
                                        {/* <Link
                                        href={item.link || ''}
                                        as={item.link || ''}
                                    > */}
                                        <div
                                            className={`text-decoration-none line-height-18 fs-15 ${
                                                item.value
                                                    ? 'colorPurple'
                                                    : 'colorPrimary'
                                            }`}
                                        >
                                            {item.title}
                                        </div>
                                        {/* </Link> */}
                                    </ListItem>
                                );
                            })}
                            <div className="mt-8">
                                {limitInput.map((d) => {
                                    return (
                                        <div className="mb-3" key={d?.key}>
                                            <RenderTextField
                                                label={d?.title}
                                                disabled={d?.disabled}
                                                value={d?.value}
                                                size="small"
                                                type="number"
                                                onKeyPress={
                                                    handleContactListLimit
                                                }
                                                onChange={(e) => {
                                                    handleContactListLimit(
                                                        e,
                                                        d?.key
                                                    );
                                                }}
                                                placeholder="Enter limit"
                                            />
                                            <Button
                                                onClick={() =>
                                                    handleToggleType(
                                                        d?.key,
                                                        d?.value
                                                    )
                                                }
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                className="mt-2 w-100"
                                                disabled={
                                                    !isInputUpdate[d?.key]
                                                }
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                onClick={() =>
                                    OpenModal(ADD_MARKETING_MODAL, {
                                        title: 'Add Marketing Email',
                                        isOpen: true,
                                        data: { selectedUser: modalData }
                                    })
                                }
                                color="primary"
                                variant="contained"
                                size="small"
                                className="mt-4 w-100"
                                // disabled={!isInputUpdate[d?.key]}
                            >
                                Add marketing email
                            </Button>
                        </List>
                    </div>
                </DialogContent>
                <DialogActions className="d-flex align-items-center justify-content-center">
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default connect(null, {
    OpenModal,
    GetUsersList,
    CommonDispatcher,
    UpdateUserProfile,
    UpdateUserPermission
})(FirmsModal);
