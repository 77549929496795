export const TOGGLE_CHANGE_OWNERSHIP_MODAL = 'TOGGLE_CHANGE_OWNERSHIP_MODAL';
export const OPEN_EXTEND_SUBSCRIPTIONS_MODAL =
    'OPEN_EXTEND_SUBSCRIPTIONS_MODAL';
export const OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL =
    'OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL';
export const OPEN_DEAL_UPDATE_MODAL = 'OPEN_DEAL_UPDATE_MODAL';
export const OPEN_INVESTORS_MODAL = 'OPEN_INVESTORS_MODAL';
export const OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL';
export const TOGGLE_CAP_TABLE_HISTORY_MODAL = 'TOGGLE_CAP_TABLE_HISTORY_MODAL';
export const TOGGLE_DISCLOSE_PRE_MONEY_MODAL =
    'TOGGLE_DISCLOSE_PRE_MONEY_MODAL';
export const TOGGLE_SUBMIT_ALERT_MODAL = 'TOGGLE_SUBMIT_ALERT_MODAL';
export const OPEN_EXTEND_CLOSING_DATE_MODAL = 'OPEN_EXTEND_CLOSING_DATE_MODAL';
export const ADD_CLIENT = 'ADD_CLIENT';
export const FIRM_LIST_MODAL = 'FIRM_LIST_MODAL';
export const SHOW_USER_MODAL = 'SHOW_USER_MODAL';
export const ADD_ADDRESS_BOOK_NOTE_MODAL = 'ADD_ADDRESS_BOOK_NOTE_MODAL';

export const SESSIONS_MODAL = 'SESSIONS_MODAL';
export const IMPORT_FIRM_MODAL = 'IMPORT_FIRM_MODAL';

export const AFFILIATED_MODAL = 'AFFILIATED_MODAL';
export const ADD_SUPPORT_USER_MODAL = 'ADD_SUPPORT_USER_MODAL';

export const ADD_MARKETING_MODAL = 'ADD_MARKETING_MODAL';
export const ADD_META_DATA_MODAL = 'ADD_META_DATA_MODAL';

export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';
export const BROAD_CAST_MESSAGES_MODAL = 'BROAD_CAST_MESSAGES_MODAL';
export const OPEN_USER_SIDE_MODAL = 'OPEN_USER_SIDE_MODAL';
export const ADD_NEW_USER_SIDE_MODAL = 'ADD_NEW_USER_SIDE_MODAL';


export const BROAD_CAST_USERS_MODAL = 'BROAD_CAST_USERS_MODAL';

export const CHANGE_USER_EMAIL_MODAL = 'CHANGE_USER_EMAIL_MODAL';
