import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    Tooltip
} from '@material-ui/core';
import {
    CloudDownload,
    CloudDownloadOutlined,
    Visibility,
    VisibilityOff
} from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import { connect } from 'react-redux';
import style from './Styles.module.scss';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { useCookies } from 'react-cookie';
import { CommonDispatcher } from '../../../redux/Common/action';
import { GetDownloadFirms } from '../../../redux/Firms/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { getQuery } from '../../../utils/common';

const DownloadFirmsModal = ({
    CommonDispatcher,
    GetDownloadFirms,
    keyword,
    type,
    tab
}: any) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const open = Boolean(anchorEl);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const popUpId = open ? `popover` : undefined;
    const history = useHistory();
    const query = getQuery(history);
    const filterParam = searchParams.get('filter');
    const tags = query.get('tags_ids');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }

    const handleOpenPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPassword('');
    };
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const onDownload = (e = null) => {
        setIsLoading(true);
        let data: any = {
            master_password: password,
            keyword,
            type,
            tab
        };
        if (filterParam || tags) {
            data.filter = {
                ...decodedFilters,
                tags_ids: tags ? tags?.split(',') : null
            };
        }
        GetDownloadFirms(token, data).then((res) => {
            if (res?.type === 'success') {
                setIsLoading(false);
                const downloadLink = document.createElement('a');
                downloadLink.href = res?.response?.url;

                downloadLink.download = 'firms.csv';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: res.response.message || 'Download successfully.'
                });
            } else if (res?.type === 'failed' && res?.response?.data?.error) {
                setIsLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message:
                        res?.response?.data?.error || 'Failed to Download.',
                    hideAlert: true,
                    error: true
                });
            } else {
                setIsLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to Download.',
                    hideAlert: true,
                    error: true
                });
            }
            setPassword('');
        });
    };

    return (
        <div className={`${style.container}`}>
            <Tooltip
                title={'Export Firms List'}
                aria-label={'Export Firms List'}
                placement="bottom"
                arrow={true}
            >
                <ToggleButton
                    classes={{ selected: 'bgColorWhite' }}
                    style={{ borderRadius: '10px' }}
                    onClick={handleOpenPopup}
                    className="ml-3"
                >
                    <CloudDownload className="colorPrimary" />
                </ToggleButton>
            </Tooltip>
            {open ? (
                <Popover
                    id={popUpId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {open ? (
                        <div className={`p-3 text-center`}>
                            <div>
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    label="Master Password *"
                                    autoComplete="new-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleTogglePasswordVisibility
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            {/* <FormHelperText
                                error={true}
                                className={
                                    errors?.master_password
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                Master password is mandatory.
                            </FormHelperText> */}
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                className="mt-3"
                                onClick={onDownload}
                            >
                                <CloudDownloadOutlined
                                    className={style.clearIcon}
                                />
                                Download
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    ) : null}
                </Popover>
            ) : null}
        </div>
    );
};

export default connect(null, { CommonDispatcher, GetDownloadFirms })(
    DownloadFirmsModal
);
