import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Add, Close, Delete, Edit } from '@material-ui/icons';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { RootState } from '../../../redux/store';
import {
    ADD_NEW_USER_SIDE_MODAL,
    OPEN_ALERT_MODAL,
    OPEN_USER_SIDE_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { useCookies } from 'react-cookie';
import AddNewUserSideModal from '../AddNewUserSideModal';
import {
    GetUserSideModal,
    DeleteNewUserSideImages
} from '../../../redux/Users/actions';
import style from './Styles.module.scss';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import imageNotFound from './../../../resources/image-not-found.webp';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const OpenUserSideModal = ({
    OpenModal,
    GetUserSideModal,
    DeleteNewUserSideImages,
    CommonDispatcher
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const modal: any =
        useSelector((store: RootState) => store?.modal?.openUserSideModal) ||
        {};
    const addNewUserSideModal = useSelector(
        (store: RootState) => store?.modal?.addNewUserSideModal
    );
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        OpenModal(OPEN_USER_SIDE_MODAL, { isOpen: false });
    };

    const fetchData = async () => {
        GetUserSideModal(token).then((r) => {
            if (r?.type === 'success') {
                const sortedData = r?.response?.popups.sort(
                    (a: { createdAt: string }, b: { createdAt: string }) => {
                        const dateA = new Date(a.createdAt).getTime();
                        const dateB = new Date(b.createdAt).getTime();
                        return dateB - dateA; // Sort in descending order
                    }
                );
                setData(sortedData);
            }
            setIsLoading(false);
        });
    };
    useEffect(() => {
        if (
            addNewUserSideModal?.isOpen == false &&
            addNewUserSideModal?.isCallGetApi
        ) {
            setIsLoading(true);
            fetchData();
        }
    }, [addNewUserSideModal?.isOpen]);
    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    return (
        <>
            {addNewUserSideModal?.isOpen ? <AddNewUserSideModal /> : null}

            <Dialog
                className="w-100"
                maxWidth={'sm'}
                fullWidth={modal?.config?.fullWidth || true}
                open={modal?.isOpen || false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    onClose={() => handleClose()}
                >
                    <div className="w-100 d-flex">{modal?.title}</div>
                </DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <div className="loader" />
                    ) : (
                        <>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() =>
                                        OpenModal(ADD_NEW_USER_SIDE_MODAL, {
                                            title: 'Add New User Side Modal',
                                            isOpen: true
                                        })
                                    }
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                >
                                    <Add />
                                    Add new popup
                                </Button>
                            </div>
                            <div className={style.dataList}>
                                {data.map((item) => (
                                    <div
                                        key={item.id}
                                        className={style.dataItem}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{
                                                marginBottom: '10px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Item #{item.id}
                                        </Typography>

                                        <img
                                            src={item.image || imageNotFound}
                                            alt={`Item ${item.id}`}
                                            style={{
                                                width: '100%',
                                                height: '200px',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                marginBottom: '15px'
                                            }}
                                        />

                                        <Typography
                                            variant="body1"
                                            style={{
                                                marginBottom: '8px',
                                                color: item.is_enabled
                                                    ? '#ffffff'
                                                    : '#333333', // Bright text for active, darker for inactive
                                                fontWeight: 'bold',
                                                backgroundColor: item.is_enabled
                                                    ? '#28a745'
                                                    : '#f8d7da', // Stronger green for active, lighter red for inactive
                                                padding: '6px 12px',
                                                borderRadius: '6px',
                                                boxShadow: item.is_enabled
                                                    ? '0 4px 10px rgba(40, 167, 69, 0.5)' // Slight glow for active
                                                    : '0 2px 6px rgba(220, 53, 69, 0.2)', // Subtle shadow for inactive
                                                textTransform: 'uppercase' // Makes the text uppercase for emphasis
                                            }}
                                        >
                                            {item.is_enabled
                                                ? 'Active'
                                                : 'Inactive'}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <strong>Description:</strong>{' '}
                                            {item.button_description}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <strong>URL:</strong>{' '}
                                            {item.popup_url}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <strong>Placement:</strong>{' '}
                                            {item.placement}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <strong>Created At:</strong>{' '}
                                            {new Date(
                                                item.createdAt
                                            ).toLocaleString()}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            style={{ marginBottom: '8px' }}
                                        >
                                            <strong>Updated At:</strong>{' '}
                                            {new Date(
                                                item.updatedAt
                                            ).toLocaleString()}
                                        </Typography>
                                        <div className="d-flex grid-column-gap-2">
                                            <Button
                                                onClick={() =>
                                                    OpenModal(
                                                        ADD_NEW_USER_SIDE_MODAL,
                                                        {
                                                            title: 'Add New User Side Modal',
                                                            isOpen: true,
                                                            data: item
                                                        }
                                                    )
                                                }
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    marginTop: '10px',
                                                    borderRadius: '20px',
                                                    padding: '8px 16px',
                                                    fontSize: '0.875rem',
                                                    textTransform: 'capitalize'
                                                }}
                                                startIcon={<Edit />}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    OpenModal(
                                                        OPEN_ALERT_MODAL,
                                                        {
                                                            isOpen: true,
                                                            title: 'Delete!',
                                                            description: `Are you sure you want to delete popup? This action cannot be undone.`,
                                                            data: {
                                                                disableBackdropClick:
                                                                    true
                                                            },
                                                            submitTitle:
                                                                'Delete',
                                                            cancelTitle: 'No',
                                                            callBack:
                                                                async () => {
                                                                    DeleteNewUserSideImages(
                                                                        token,
                                                                        {
                                                                            id: item?.id
                                                                        }
                                                                    )
                                                                        .then(
                                                                            (
                                                                                r
                                                                            ) => {
                                                                                if (
                                                                                    r?.type ===
                                                                                    'success'
                                                                                ) {
                                                                                    CommonDispatcher(
                                                                                        SHOW_SNACKBAR,
                                                                                        {
                                                                                            isShowing:
                                                                                                true,
                                                                                            message:
                                                                                                'popup deleted successfully.',
                                                                                            hideAlert:
                                                                                                true
                                                                                        }
                                                                                    );
                                                                                    fetchData();
                                                                                } else {
                                                                                    CommonDispatcher(
                                                                                        SHOW_SNACKBAR,
                                                                                        {
                                                                                            isShowing:
                                                                                                true,
                                                                                            message:
                                                                                                'Failed to delete popup.',
                                                                                            hideAlert:
                                                                                                true,
                                                                                            error: true
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }
                                                                        )
                                                                        .catch(
                                                                            (
                                                                                e
                                                                            ) => {
                                                                                CommonDispatcher(
                                                                                    OPEN_ALERT_MODAL,
                                                                                    {
                                                                                        is_open:
                                                                                            false
                                                                                    }
                                                                                );
                                                                            }
                                                                        )
                                                                        .finally(
                                                                            (
                                                                                e
                                                                            ) => {
                                                                                CommonDispatcher(
                                                                                    OPEN_ALERT_MODAL,
                                                                                    {
                                                                                        is_open:
                                                                                            false
                                                                                    }
                                                                                );
                                                                            }
                                                                        );
                                                                }
                                                        }
                                                    )
                                                }
                                                color="secondary"
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    marginTop: '10px',
                                                    borderRadius: '20px',
                                                    padding: '8px 16px',
                                                    fontSize: '0.875rem',
                                                    textTransform: 'capitalize'
                                                }}
                                                startIcon={<Delete />}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            ;
                        </>
                    )}
                </DialogContent>
                <DialogActions className="d-flex align-items-center justify-content-center">
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className="mb-4"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default connect(null, {
    OpenModal,
    GetUserSideModal,
    DeleteNewUserSideImages,
    CommonDispatcher
})(OpenUserSideModal);
